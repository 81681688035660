/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { ApiResponse } from 'common/data/entities/api-response';
import { ICategories } from '../../domain/entities/categories';
import { ILandingApi } from './landing-api';
import { Endpoints } from 'assets/Endpoints';
import { storeGet } from 'common/data/remote/store-requests';
import { ErrorResponseBase } from 'common/domain/entities/error-response';

export class LandingApiImpl implements ILandingApi {
	async getCategories(isVisible: boolean): Promise<ICategories[]> {
		try {
			const resp = await storeGet({
				url: Endpoints.CATEGORIES.format(isVisible),
			});
			const response = resp.data as ApiResponse<ICategories[]>;
			return response.data;
		} catch (error) {
			throw error;
		}
	}

	async getCategoryById(id: string): Promise<ICategories> {
		try {
			const resp = await storeGet({
				url: Endpoints.CATEGORY_BY_ID.format(id),
			});
			const response = resp.data as ApiResponse<ICategories>;
			return response.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}
}
